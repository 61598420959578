<template>
  <div class="container mx-auto">
    <div class="flex" :key="item" v-for="(item, k) of content">
      <div class="w-1/3">
        {{ k }}
        <svg
          width="422"
          height="438"
          viewBox="0 0 422 438"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="20"
            y="16"
            width="382"
            height="382"
          >
            <rect
              x="4"
              y="203.749"
              width="288.144"
              height="297.34"
              rx="40"
              transform="rotate(-45 4 203.749)"
              fill="#014C40"
            />
          </mask>
          <g mask="url(#mask1)">
            <rect
              x="-248.853"
              width="689.404"
              height="460.323"
              fill="url(#pattern1)"
            />
          </g>
          <defs>
            <pattern
              id="pattern1"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use
                xlink:href="#image1"
                transform="translate(-0.00078373) scale(0.000607011 0.000909091)"
              />
            </pattern>
            <image
              id="image1"
              width="2250"
              height="1100"
              :xlink:href="`/assets/${item.image}`"
            />
          </defs>
        </svg>
      </div>
      <div class="ml-24">
        <div class="text-xl font-bold">{{ item.title }}</div>
        <div
          class="text-sm mt-8 max-w-xl text-gray"
          v-html="item.content"
        ></div>
        <mainBtn
          class="mt-8"
          text="Plačiau"
          icon="arrow"
          iconPos="right"
          textColor="green"
          bgColor="yellow"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          title: "Lorem ipsum dolor",
          content:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.",
          image: "fisherman.jpg",
        },
        {
          title: "Lorem ipsum dolor simaet mauntis",
          content:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.",
          image: "fisherman.jpg",
        },
      ],
    };
  },
};
</script>

<style></style>
